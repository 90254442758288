import { styled, Box, Typography as MuiTypography } from "@mui/material";

export const Wrapper = styled(Box)(
  ({
    theme: {
      palette: { custom },
      breakpoints
    }
  }) => `
    &.MuiBox-root {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px 34px;
      background-color: ${custom.turquoise.main};

      ${[breakpoints.up("md")]} {
        padding: 19px 80px;
      }
    }
  `
);

export const Typography = styled(MuiTypography)(
  ({
    theme: {
      typography: { body1, body2 },
      palette: { custom },
      breakpoints
    }
  }) => `
    &.MuiTypography-root {
      font-size: ${body2.mobile.fontSize};
      font-weight: ${body2.mobile.fontWeight};
      line-height: 21px;
      text-align: center;
      color: ${custom.white.main};

      ${[breakpoints.up("md")]} {
        font-size: ${body1.desktop.fontSize};
        font-weight: ${body1.desktop.fontWeight};
        line-height: 26px;
      }
    }
  `
);
