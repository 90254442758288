import { useEffect, useMemo } from "react";
import { useLocation, useSearchParams, matchPath } from "react-router-dom";
import { importedModules } from "modules-routes";
import { useAuthState, useShopListData } from "../../../hooks";

export const SelectedShopObserver = () => {
  const { token } = useAuthState();

  const [searchParams, setSearchParams] = useSearchParams();

  const { pathname } = useLocation();

  const { data } = useShopListData(!!token);

  const selectedShopId = searchParams.get("shop");

  const modulesConfig = useMemo(
    () =>
      importedModules
        .map(({ getPageConfig }) => getPageConfig())
        .filter(({ requiredPermission, disabled }) => requiredPermission && !disabled),
    []
  );

  useEffect(() => {
    const newStructurePage = modulesConfig.some(({ path }) => matchPath(pathname, path));

    const selectedShopIdValue = data?.find((shop) => shop.shopId === Number(selectedShopId));

    if (
      newStructurePage &&
      data &&
      data[0]?.shopId &&
      (!selectedShopId || (selectedShopId && !selectedShopIdValue))
    ) {
      searchParams.set("shop", data[0].shopId.toString());
      setSearchParams(searchParams);
    }
  }, [selectedShopId, data, pathname]);

  return null;
};
