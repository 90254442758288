import { Heading, QueryConfigProvider, Subheading, ContentWrapper } from "shared/components";
import { useOpenFeatureFlag } from "shared/hooks";
import { useTranslation } from "react-i18next";
import { EmptyStateText } from "./TriggerMailsPaymentSettingsList.styles";
import { BrowseAbandonmentPaymentSettingsForm } from "../BrowseAbandonmentPaymentSettingsForm";
import { ComeBackMailingPaymentSettingsForm } from "../ComeBackMailingPaymentSettingsForm";

export const TriggerMailsPaymentSettingsList = () => {
  const { t } = useTranslation();

  const { value: browseAbandonmentVisible } = useOpenFeatureFlag(
    "browse_abandonment_trigger_enabled"
  );

  const { value: comeBackMailingVisible } = useOpenFeatureFlag("come_back_mailing_trigger_enabled");

  return (
    <ContentWrapper>
      <Heading>{t("settingsModule.triggerMailsPayment.heading")}</Heading>
      <Subheading>{t("settingsModule.triggerMailsPayment.subheading")}</Subheading>
      {browseAbandonmentVisible && (
        <QueryConfigProvider queryName="browse-abandonment-payment">
          <BrowseAbandonmentPaymentSettingsForm />
        </QueryConfigProvider>
      )}
      {comeBackMailingVisible && (
        <QueryConfigProvider queryName="come-back-mailing-payment">
          <ComeBackMailingPaymentSettingsForm />
        </QueryConfigProvider>
      )}
      {!(browseAbandonmentVisible || comeBackMailingVisible) && (
        <EmptyStateText>{t("settingsModule.triggerMailsPayment.emptyState")}</EmptyStateText>
      )}
    </ContentWrapper>
  );
};
