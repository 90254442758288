import { useCallback, useState } from "react";
import { Theme, useMediaQuery } from "@mui/material";
import { connect } from "react-redux";
import { Iterable } from "immutable";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  NavigationButtonsWrapper,
  SettingsWrapper,
  NavigationBarWrapper
} from "../../../navigation/PrivateNavigationBar/PrivateNavigationBar.styles";
import { NavigationDrawer } from "../../../navigation/NavigationDrawer";
import { NavigationBarButton } from "../../../navigation/NavigationBarButton";
import { AccountButton } from "../../../navigation/AccountButton";
import { LegacyAccountMenu } from "../LegacyAccountMenu";
import { HelpButton } from "../../../navigation/HelpButton";
import { LanguageSwitch } from "../../../navigation/LanguageSwitch";
import { LegacyAccountDrawer } from "../LegacyAccountDrawer";
import { UptainLogo } from "../../../navigation/UptainLogo";
import { PathConfig } from "../../../../types";
import { useNavigationConfig } from "../../../../hooks";
import { useLegacyQuestionnaireCompleted } from "../../hooks/useLegacyQuestionnaireCompleted";
import { ShopifyBanner } from "../../../navigation/ShopifyBanner";
import { LegacyPrivateNavigationBarProps } from "./LegacyPrivateNavigationBar.props";

const Component = ({ shopId }: LegacyPrivateNavigationBarProps) => {
  const desktop = useMediaQuery(({ breakpoints }: Theme) => breakpoints.up("xl"));

  const navigate = useNavigate();

  const questionnaireCompleted = useLegacyQuestionnaireCompleted(shopId);

  const navigationRoutes = useNavigationConfig();

  const [accountAnchor, setAccountAnchor] = useState<null | HTMLElement>(null);

  const [navigationDrawerOpen, setNavigationDrawerOpen] = useState(false);

  const handleOpenNavigation = useCallback(() => {
    setNavigationDrawerOpen((state) => !state);
    setAccountAnchor(null);
  }, []);

  const handleCloseNavigation = useCallback(() => {
    setNavigationDrawerOpen(false);
  }, []);

  const handleOpenAccount = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAccountAnchor(accountAnchor ? null : event.currentTarget);
      setNavigationDrawerOpen(false);
    },
    [accountAnchor]
  );

  const handleCloseAccount = useCallback(() => {
    setAccountAnchor(null);
  }, []);

  const handleNavigate = useCallback(
    ({ path, includeShopId }: PathConfig) => {
      setNavigationDrawerOpen(false);
      navigate({
        pathname: path,
        search: includeShopId ? `?shop=${shopId}` : ""
      });
    },
    [shopId]
  );

  return (
    <NavigationBarWrapper>
      <ShopifyBanner shopId={shopId} />
      <AppBar position="relative">
        <Toolbar>
          <UptainLogo active={navigationDrawerOpen} onClick={handleOpenNavigation} />
          {desktop && (
            <NavigationButtonsWrapper>
              {navigationRoutes.map((route) => (
                <NavigationBarButton
                  key={route.translationKey}
                  route={route}
                  onClick={handleNavigate}
                  shopId={shopId}
                />
              ))}
            </NavigationButtonsWrapper>
          )}
          <SettingsWrapper>
            <LanguageSwitch />
            <HelpButton />
            <AccountButton
              showBadge={!questionnaireCompleted}
              active={Boolean(accountAnchor)}
              onClick={handleOpenAccount}
            />
            {desktop && (
              <LegacyAccountMenu
                anchorEl={accountAnchor}
                onClose={handleCloseAccount}
                legacyShopId={shopId}
              />
            )}
          </SettingsWrapper>
        </Toolbar>
      </AppBar>
      {!desktop && (
        <>
          <LegacyAccountDrawer
            open={Boolean(accountAnchor)}
            onClose={handleCloseAccount}
            legacyShopId={shopId}
          />
          <NavigationDrawer
            routes={navigationRoutes}
            open={navigationDrawerOpen}
            onClose={handleCloseNavigation}
            onNavigate={handleNavigate}
            shopId={shopId}
          />
        </>
      )}
    </NavigationBarWrapper>
  );
};

const mapStateToProps = (state: Iterable<string, any>) => ({
  shopId: state.getIn(["customerBackendDashboard", "userData", "selectedShopId"]) as number
});

export const LegacyPrivateNavigationBar = connect(mapStateToProps)(Component);
