import styled from 'styled-components';

export const ContentWrapper = styled.div`
  min-height: calc(100vh - 70px);
  padding-bottom: ${(props) => (props.dashboard ? '70px' : '40px')};
  padding-top: ${(props) => (props.topOffset + 56)}px;
  transition: padding-top 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  @media only screen and (min-width: 600px) {
    padding-top: ${(props) => (props.topOffset + 64)}px;
  }

  @media only screen and (max-width: ${(props) =>
    `${props.theme.navBreakpoint}px`}) {
    min-height: 100vh;
  }

  @media screen and (min-width: ${(props) => props.theme.small}) {
    padding-bottom: 30px;
  }
`;
