import { PropsWithChildren, useCallback, useState } from "react";
import { BannersStateContextProvider, BannersState } from "../../../context";

export const BannerStateWrapper = ({ children }: PropsWithChildren) => {
  const [bannerHeight, setBannerHeight] = useState<number>(0);

  const updateBannerHeight = useCallback((height: number) => {
    setBannerHeight(height);
  }, []);

  const initialState: BannersState = {
    bannerHeight,
    setBannerHeight: updateBannerHeight
  };

  return <BannersStateContextProvider value={initialState}>{children}</BannersStateContextProvider>;
};
