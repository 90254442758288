import { apiClient } from "../utils";
import { GetShopifyUpdateBannerStatus } from "../../api/models";

export const getShopifyUpdateBannerStatus = async (shopId: number) => {
  const { data } = await apiClient.get<GetShopifyUpdateBannerStatus>(
    `${shopId}/shopify-update-banner-status`
  );

  return data?.update;
};
