import { useCallback } from "react";
import { Collapse } from "@mui/material";
import { Link } from "../../form";
import { useBannerState, useOpenFeatureFlag, useShopifyUpdateBannerStatus } from "../../../hooks";
import { Wrapper, Typography } from "./ShopifyBanner.styles";
import { useTranslation, Trans } from "react-i18next";
import { ShopifyBannerProps } from "./ShopifyBanner.props";

export const ShopifyBanner = ({ shopId }: ShopifyBannerProps) => {
  const { t } = useTranslation();

  const { isShopifyShop, loading } = useShopifyUpdateBannerStatus(shopId);

  const { value: bannerEnabled } = useOpenFeatureFlag("shopify_banner_enabled");

  const { setBannerHeight } = useBannerState();

  const handleBannerStateChange = useCallback((node: HTMLElement) => {
    if (node.clientHeight > 0) {
      setBannerHeight(node.clientHeight);
    } else {
      setBannerHeight(0);
    }
  }, []);

  const showBanner = isShopifyShop && !loading && !!bannerEnabled;

  return (
    <Collapse
      in={showBanner}
      onExited={handleBannerStateChange}
      onEntered={handleBannerStateChange}
    >
      <Wrapper>
        <Typography variant="h1">
          <Trans
            i18nKey={`navigation.banners.shopify-update.text`}
            t={t}
            values={{
              linkText: t(`navigation.banners.shopify-update.linkText`)
            }}
            components={{
              linkComponent: (
                <Link
                  type="tertiary"
                  href={t(`navigation.banners.shopify-update.link`)}
                  target="_blank"
                  inheritFonts
                />
              )
            }}
          />
        </Typography>
      </Wrapper>
    </Collapse>
  );
};
