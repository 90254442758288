import { QueryConfigProvider, ContentWrapper } from "shared/components";
import { DEFAULT_QUERY_LIMIT } from "config/constants";
import { BrowseAbandonmentVoucherSettingsList } from "../BrowseAbandonmentVoucherSettingsList";
import { BrowseAbandonmentTextSettingsList } from "../BrowseAbandonmentTextSettingsList";
import { BrowseAbandonmentContentHeadingForm } from "../BrowseAbandonmentContentHeadingForm";

export const BrowseAbandonmentSettingsContent = () => {
  return (
    <ContentWrapper>
      <QueryConfigProvider queryName="browseAbandonmentConfig">
        <BrowseAbandonmentContentHeadingForm />
      </QueryConfigProvider>
      <QueryConfigProvider queryName="browseAbandonmentVouchers" initialLimit={DEFAULT_QUERY_LIMIT}>
        <BrowseAbandonmentVoucherSettingsList />
      </QueryConfigProvider>
      <QueryConfigProvider queryName="browseAbandonmentTexts" initialLimit={DEFAULT_QUERY_LIMIT}>
        <BrowseAbandonmentTextSettingsList />
      </QueryConfigProvider>
    </ContentWrapper>
  );
};
