import { createContext } from "react";
import { BannersState } from "./bannersState.types";

const initialState: BannersState = {
  bannerHeight: 0,
  setBannerHeight: () => {}
};

export const bannersStateContext = createContext<BannersState>(initialState);

export const BannersStateContextProvider = bannersStateContext.Provider;
