import { useQueryWrapper } from "../useQueryWrapper";
import { getShopifyUpdateBannerStatus } from "../../api";

export const useShopifyUpdateBannerStatus = (shopId?: number) => {
  const { data, isLoading } = useQueryWrapper(
    ["shopify-status", shopId],
    () => getShopifyUpdateBannerStatus(shopId!),
    {
      enabled: !!shopId,
      refetchOnMount: false,
      retry: false
    }
  );

  return { isShopifyShop: data, loading: isLoading };
};
