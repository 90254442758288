import { PropsWithChildren } from "react";
import { GlobalStyles } from "config/theme/GlobalStyles";
import { WrapperStyles } from "./PageWrapper.styles";

export const PageWrapper = ({ children }: PropsWithChildren) => {
  return (
    <>
      {/* Placed global styles at page wrapper for now to change background color only at new pages */}
      <GlobalStyles />
      <WrapperStyles>{children}</WrapperStyles>
    </>
  );
};
