import { styled, Box } from "@mui/material";
import background_lines_small from "assets/images/background_lines/small.png";
import background_lines_large from "assets/images/background_lines/large.png";

export const WrapperStyles = styled(Box)(
  ({
    theme: {
      palette: {
        custom: { background }
      },
      breakpoints
    }
  }) => {
    return `
      &.MuiBox-root {
        background-color: ${background};
        background-image: url(${background_lines_small});
        background-size: cover;
        background-repeat: no-repeat;
        background-clip: border-box;
        background-position: center;
        background-attachment: fixed;
        width: 100%;
        min-height: 100vh;

        ${[breakpoints.up("lg")]} {
          background-image: url(${background_lines_large});
        }
      }
    `;
  }
);
