import { FORM_ERROR } from "final-form";
import { useCallback } from "react";
import { NetworkError } from "../../types";
import { useNotifications } from "../../hooks/useNotifications";

export const useErrorFormatter = () => {
  const { showNotification } = useNotifications();

  return useCallback((error: NetworkError) => {
    let errorTranslationKey = "global.error.serverUnavailable";

    const contextErrors = error.response?.data?.context?.errors ?? {};

    const contextErrorsKeys = Object.keys(contextErrors);

    if (contextErrorsKeys.length > 0) {
      return contextErrorsKeys.reduce((prev, key) => {
        return {
          ...prev,
          [key]: contextErrors[key][0]
        };
      }, {} as Record<string, string>);
    }

    if (error.response?.data?.errorKey) {
      errorTranslationKey = error.response?.data?.errorKey;
    }

    showNotification(errorTranslationKey, "error");

    return {
      [FORM_ERROR]: errorTranslationKey
    };
  }, []);
};
