import {
  styled,
  AppBar as MuiAppBar,
  Toolbar as MuiToolbar,
  ToolbarProps,
  Box as MuiBox
} from "@mui/material";

export const NavigationBarWrapper = styled(MuiBox)(
  () => `
    &.MuiBox-root {
      display: flex;
      position: fixed;
      flex-direction: column;
      width: 100%;
      z-index: 10000;
    }
  `
);

export const AppBar = styled(MuiAppBar)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    background-color: ${custom.background};
    box-shadow: ${custom.shadows.navigation};
    z-index: 10000;
  `
);

export const Toolbar = styled((props: ToolbarProps) => <MuiToolbar {...props} disableGutters />)(
  () => `
    display: flex;
    justify-content: flex-start;
    padding: 8px;
  `
);

export const NavigationButtonsWrapper = styled(MuiBox)(
  () => `
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  `
);

export const SettingsWrapper = styled(MuiBox)(
  () => `
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
  `
);
