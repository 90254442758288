import {
  Heading,
  Subheading,
  DescriptionText,
  TextInput,
  ContainedButton,
  LoadingIndicator,
  VisibilitySwitch,
  ContentWrapper
} from "shared/components";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Field } from "react-final-form";
import { InputWrapper, ButtonWrapper, Collapse } from "./ShopLinksForm.styles";
import { linkValidator, unsubscribeLinkValidator } from "./ShopLinksForm.validation";
import { useLinksConfigData, useUpdateLinksConfig } from "../../hooks";

export const ShopLinksForm = () => {
  const [unsubscribeVisible, setUnsubscribeVisible] = useState(false);

  const { t } = useTranslation();

  const { initialState, loading } = useLinksConfigData();

  const submit = useUpdateLinksConfig();

  const handleToggleVisibility = useCallback(() => {
    setUnsubscribeVisible((prev) => !prev);
  }, []);

  return (
    <ContentWrapper>
      <Heading>{t("settingsModule.shopLinks.heading")}</Heading>
      <Subheading>{t("settingsModule.shopLinks.subheading")}</Subheading>
      <DescriptionText withMargin>{t("settingsModule.shopLinks.description")}</DescriptionText>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <Form
          initialValues={initialState}
          onSubmit={submit}
          render={({ dirty, handleSubmit, submitting, submitErrors }) => (
            <>
              <InputWrapper>
                <Field
                  name="link"
                  validate={linkValidator}
                  render={({
                    input: { value, onChange, onBlur, onFocus },
                    meta: { error, touched, modifiedSinceLastSubmit }
                  }) => (
                    <TextInput
                      marginBottom={25}
                      label={t("settingsModule.shopLinks.form.shopLink")}
                      placeholder="https://uptain.de/"
                      value={value}
                      onChange={onChange}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      error={
                        (error || (submitErrors?.["link"] && !modifiedSinceLastSubmit)) && touched
                      }
                      errorMessage={t(submitErrors?.["link"] ?? error)}
                    />
                  )}
                />
              </InputWrapper>
              <VisibilitySwitch active={unsubscribeVisible} onClick={handleToggleVisibility}>
                {t("settingsModule.shopLinks.form.extraSettingsToggle")}
              </VisibilitySwitch>
              <Collapse in={unsubscribeVisible}>
                <InputWrapper>
                  <Field
                    name="unsubscribeLink"
                    validate={unsubscribeLinkValidator}
                    render={({
                      input: { value, onChange, onBlur, onFocus },
                      meta: { error, touched, modifiedSinceLastSubmit }
                    }) => (
                      <TextInput
                        label={t("settingsModule.shopLinks.form.unsubscribeLink")}
                        placeholder="https://uptain.de/unsubscribe"
                        value={value}
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        error={
                          (error ||
                            (submitErrors?.["unsubscribeLink"] && !modifiedSinceLastSubmit)) &&
                          touched
                        }
                        errorMessage={t(submitErrors?.["unsubscribeLink"] ?? error)}
                      />
                    )}
                  />
                </InputWrapper>
              </Collapse>
              <ButtonWrapper>
                <ContainedButton
                  loading={submitting}
                  disabled={!dirty}
                  type="submit"
                  onClick={handleSubmit}
                >
                  {t("settingsModule.shopLinks.form.saveButton")}
                </ContainedButton>
              </ButtonWrapper>
            </>
          )}
        />
      )}
    </ContentWrapper>
  );
};
