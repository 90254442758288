import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { DefaultTheme, ThemeProvider as StyledThemeProvider } from "styled-components";
import { theme, muiTheme } from "config/theme";
import { legacyLocales } from "config/localization";
import { ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  NotificationProvider,
  OpenFeatureContextObserver,
  OpenFeatureContextProvider,
  SelectedShopObserver,
  BannerStateWrapper
} from "shared/components";

import "./shared/utils/localization/i18next";

import { store } from "./legacy/configureStore";
import LanguageProvider from "./legacy/containers/LanguageProvider";
import Application from "./legacy/containers/App";

// TODO: remove when styles will be updated
import "sanitize.css/sanitize.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-notifications/lib/notifications.css";

const queryClient = new QueryClient();

function App() {
  return (
    <BannerStateWrapper>
      <OpenFeatureContextProvider>
        <BrowserRouter>
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />
              <LanguageProvider messages={legacyLocales}>
                <ThemeProvider theme={muiTheme}>
                  <StyledThemeProvider theme={theme as DefaultTheme}>
                    <NotificationProvider>
                      <Application />
                      <OpenFeatureContextObserver />
                      <SelectedShopObserver />
                    </NotificationProvider>
                  </StyledThemeProvider>
                </ThemeProvider>
              </LanguageProvider>
            </QueryClientProvider>
          </Provider>
        </BrowserRouter>
      </OpenFeatureContextProvider>
    </BannerStateWrapper>
  );
}

export default App;
