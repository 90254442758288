import { styled, Typography as MuiTypography } from "@mui/material";

export const EmptyStateText = styled(MuiTypography)(
  ({
    theme: {
      palette: { custom },
      typography: { body2 },
      breakpoints
    }
  }) => `
    &.MuiTypography-root {
      margin-top: 50px;
      text-align: center;
      font-size: ${body2.mobile.fontSize};
      font-weight: ${body2.mobile.fontWeight};
      line-height: ${body2.mobile.lineHeight};
      color: ${custom.white.light};

      ${[breakpoints.up("md")]} { 
        font-size: ${body2.tablet.fontSize};
        font-weight: ${body2.tablet.fontWeight};
        line-height: ${body2.tablet.lineHeight};
      }

      ${[breakpoints.up("xl")]} { 
        font-size: ${body2.desktop.fontSize};
        font-weight: ${body2.desktop.fontWeight};
        line-height: ${body2.desktop.lineHeight};
      }
    } 
  `
);
