import { Box, styled } from "@mui/material";
import { ContentWrapperProps } from "./ContentWrapper.props";

export const ContentWrapperStyles = styled((props: ContentWrapperProps) => <Box {...props} />, {
  shouldForwardProp: (prop) => prop !== "topOffset"
})(
  ({ theme: { breakpoints }, topOffset }) => `
    &.MuiBox-root {
      margin: 0 20px;
      padding: ${topOffset + 80}px 0 65px;
      transition: padding-top 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      ${[breakpoints.up("sm")]} {
        margin: 0 auto;
        width: 500px;
      }

      ${[breakpoints.up("md")]} { 
        width: 800px;
      }

      ${[breakpoints.up("xl")]} { 
        width: 950px;
      }
    }
  `
);
