import { NavigationBarDrawerStyles } from "./NavigationBarDrawer.styles";
import { useBannerState } from "../../../hooks";
import { NavigationBarDrawerProps } from "./NavigationBarDrawer.props";

export const NavigationBarDrawer = ({ children, ...props }: NavigationBarDrawerProps) => {
  const { bannerHeight } = useBannerState();

  return (
    <NavigationBarDrawerStyles topOffset={bannerHeight} {...props}>
      {children}
    </NavigationBarDrawerStyles>
  );
};
